import React, { Suspense } from 'react'
import RequireUser from './contexts/RequireUser'; 
import FullScreenLoader from "./components/FullScreenLoader"
import { registerLicense } from '@syncfusion/ej2-base';

import { Route, Routes } from 'react-router-dom';
import Login from "./pages/CommonPages/Login"
import NotFoundPage from "./pages/CommonPages/NotFoundPage"

const HomePage = React.lazy(() => import('./pages/CommonPages/HomePage'));
const Dashboard = React.lazy(() => import('./pages/CommonPages/Dashboard'));
const AddUser = React.lazy(() => import('./pages/CommonPages/AddUser'));
const AddGradinite = React.lazy(() => import('./pages/CommonPages/AddGradinita'));
const ImportData = React.lazy(() => import('./pages/CommonPages/ImportData'));

const Submodules = React.lazy(() => import('./pages/Activitati/Submodules'));
const Activities = React.lazy(() => import('./pages/Activitati/Activities'));
const OneActivity = React.lazy(() => import('./pages/Activitati/OneActivity'));
const AllActivities = React.lazy(() => import('./pages/Activitati/AllActivities'));
const ActivitatiSettings = React.lazy(() => import('./pages/Activitati/ActivitatiSettings'));
const ChildrenProgress = React.lazy(() => import('./pages/Activitati/ChildrenProgress'));
const ActivityPlanning = React.lazy(() => import('./pages/Activitati/ActivityPlanning'));
const PlannedActivity = React.lazy(() => import('./pages/Activitati/PlannedActivity'));
const CreateActivity = React.lazy(() => import('./pages/Activitati/CreateActivity'));
const DayReport = React.lazy(() => import('./pages/Activitati/DayReport'));

const Common = React.lazy(() => import('./components/Common'));

const Copii = React.lazy(() => import('./pages/GradiniteGeneral/Copii'));
const CopilPage = React.lazy(() => import('./pages/GradiniteGeneral/CopilPage'));
const Gradinite = React.lazy(() => import('./pages/GradiniteGeneral/Gradinite'));
const Parinti = React.lazy(() => import('./pages/GradiniteGeneral/Parinti'));
const ParintePage = React.lazy(() => import('./pages/GradiniteGeneral/ParintePage'));
const GradinitaPage = React.lazy(() => import('./pages/GradiniteGeneral/GradinitaPage'));
const GrupaPage = React.lazy(() => import('./pages/GradiniteGeneral/GrupaPage'));
const Specialisti = React.lazy(() => import('./pages/GradiniteGeneral/Specialisti'));
const SpecialistPage = React.lazy(() => import('./pages/GradiniteGeneral/SpecialistPage'));
registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE);

const App = () => {

  return (
    <div>
      <Suspense fallback={<FullScreenLoader />}>
      <Routes>
        <Route>
        <Route path="/404error" element={<NotFoundPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<HomePage />} />

        <Route path='/' element={<Common />}>
          <Route element={<RequireUser allowedRoles={[1, 2]} />}>
                  <Route path="dash/" element={<Dashboard />} />
                  <Route path="dash/activities/" element={<AllActivities />} />
                  <Route path="dash/activities/:activity_id" element={<OneActivity />} />
                  <Route path="dash/submodules" element={<Submodules />} />
                  <Route path="dash/submodule/:submodule_id/" element={<Activities />} />
                  <Route path="dash/submodule/:submodule_id/activity/:activity_id" element={<OneActivity />} />
                  <Route path="dash/activityplanning" element={<ActivityPlanning />} />
                  <Route path="dash/plannedactivity/:planned_activity_id" element={<PlannedActivity />} />
                  <Route path="dash/childrenprogress/:copil_id" element={<ChildrenProgress />} />
                  <Route path="dash/CreateActivity" element={<CreateActivity />} />
                  <Route path="dash/DayReport/:specialist_id" element={<DayReport />} />
            </ Route>
            <Route element={<RequireUser allowedRoles={[1, 2]} />}>
                  <Route path="dash/copii" element={<Copii />} />
                  <Route path="dash/copii/:copil_id" element={<CopilPage />} />
                  <Route path="dash/gradinite" element={<Gradinite />} />
                  <Route path="dash/parinti" element={<Parinti />} />
                  <Route path="dash/parinti/:parinte_id" element={<ParintePage />} />
                  <Route path="dash/gradinite/:gradinita_id" element={<GradinitaPage />} />
                  <Route path="dash/grupe/:grupa_id" element={<GrupaPage />} />
                  <Route path="dash/specialisti" element={<Specialisti />} />
                  <Route path="dash/specialisti/:specialist_id" element={<SpecialistPage />} />
            </ Route>
            <Route element={<RequireUser allowedRoles={[1]} />}>
                  <Route path="dash/adduser" element={<AddUser />} />
                  <Route path="dash/addgradinita" element={<AddGradinite />} />
                  <Route path="dash/importdata" element={<ImportData />} />
                  <Route path="dash/activities/settings" element={<ActivitatiSettings />} />
            </ Route>
        </Route>
        </Route>
      </Routes>
      </Suspense>
    </div>
  );
}

export default App;