import React, { createContext, useContext, useState, useEffect } from "react";


const StateContext = createContext();

const initialState = {
    chat: false,
    cart: false,
    userProfile: false,
    notification: false,
}

export const ContextProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem("accessTokenOfUserApp"));
    const [ userData, setUserData ] = useState([]);
    const [ userRole, setUserRole ] = useState("")
    const [ userID, setUserID ] = useState("")
    const [ editMode, setEditMode ] = useState("")


    


    const handleLogout = () => {
        setToken(null);
        localStorage.removeItem("accessTokenOfUserApp");
    }



    useEffect(() => {
        const fetchUser = async () => {
            const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json"},
            body: JSON.stringify({token}),
            };
    
        const response = await fetch(`${process.env.REACT_APP_API_URL}/usergettoken`, requestOptions);
        const userDataJson = await response.json();
    
        if (!response.ok) {
            setToken(null);
            localStorage.removeItem("accessTokenOfUserApp");
        }
        setUserRole(userDataJson.role)
        setUserID(userDataJson.userid)
        setUserData(userDataJson)
        localStorage.setItem("accessTokenOfUserApp", userDataJson.token);
        localStorage.setItem("role", userDataJson.role);
    
        };
    
        fetchUser();
    
        }, [token]);
        
    

    return (
        <StateContext.Provider
            value={{
                token,
                setToken,
                handleLogout,
                userRole,
                userID,
                userData,
                editMode,
                setEditMode
            }}>
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () =>  useContext(StateContext);